<template>
  <div class="page bg-white" id="reorganize">
    <div class="tabsCls">
      <el-tabs size="small" v-model="selectTab" @tab-click="handleClick">
        <el-tab-pane v-for="item in tabsList" :key="item.value" :label="item.name"
                     :name="item.value"></el-tab-pane>
      </el-tabs>
    </div>
    <div class="contens">
      <div v-show="selectTab == '999'">
        <el-form :model="inputForm" ref="inputForm" :rules="rules" label-width="120px"
                 size="small">
          <el-row :gutter="20">
            <el-col :span="11">
              <el-form-item label="保护事件名称" prop="protectEventName">
                <el-input
                    v-model.trim="inputForm.protectEventName"
                    placeholder="请输入保护事件名称(限50字)"
                    maxlength="50"
                    :disabled="inputType"
                    clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="保护级别" prop="protectionLevel">
                <el-select
                    v-model="inputForm.protectionLevel"
                    clearable
                    placeholder="请选择保护级别"
                    :disabled="inputType"
                    style="width: 100%"
                >
                  <el-option
                      v-for="item in this.$dictUtils.getDictList('protection_level')"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="保护周期" prop="mttr" class="adsfaads">
                <el-date-picker
                    v-model="inputForm.mttr"
                    type="daterange"
                    style="width: 100%;"
                    :disabled="inputType"
                    value-format="yyyy-MM-dd"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="保护类型" prop="protectionType">
                <el-select
                    v-model="inputForm.protectionType"
                    clearable
                    placeholder="请选择保护类型"
                    :disabled="inputType"
                    style="width: 100%"
                >
                  <el-option
                      v-for="item in this.$dictUtils.getDictList('protection_type')"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="保护使用材料" prop="protectiveMaterial">
                <el-input
                    v-model.trim="inputForm.protectiveMaterial"
                    placeholder="请输入保护使用材料(限50字)"
                    maxlength="50"
                    :disabled="inputType"
                    clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="保护设备" prop="protectiveEquipment">
                <el-input
                    v-model.trim="inputForm.protectiveEquipment"
                    placeholder="请输入保护设备(限50字)"
                    maxlength="50"
                    :disabled="inputType"
                    clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="保护费用（元）" prop="protectionCost">
                <el-input
                    v-model.trim.number="inputForm.protectionCost"
                    placeholder="请输入保护费用"
                    @input="jiage"
                    @blur="save2Data"
                    maxlength="10"
                    :disabled="inputType"
                    clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="保护人员" prop="protectionPersonnel">
                <el-input
                    v-model.trim="inputForm.protectionPersonnel"
                    placeholder="请输入保护人员(限50字)"
                    maxlength="50"
                    :disabled="inputType"
                    clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="22">
              <el-form-item label="保护具体措施" prop="specificProtectionMeasures">
                <el-input type="textarea" rows="5" resize="none" :disabled="inputType"
                          v-model.trim="inputForm.specificProtectionMeasures" maxlength="200"
                          placeholder="请输入保护具体措施(限200字)"
                          clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="22">
              <el-form-item label="备注" prop="remark">
                <el-input type="textarea" rows="5" resize="none" :disabled="inputType"
                          v-model.trim="inputForm.remark" maxlength="100"
                          placeholder="其它需要特别说明的事项或信息(限100字)"
                          clearable></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>

      <div v-show="selectTab == '2'">
        <el-form size="small" ref="inputForm" inline :model="search2" label-width="100px">
          <el-form-item label="藏品名称" prop="name">
            <el-input v-model.trim="search2.name"
                      placeholder="请输入藏品名称(限50字)"
                      maxlength="50" clearable></el-input>
          </el-form-item>
          <el-form-item label="藏品编号" prop="code">
            <el-input v-model.trim="search2.code"
                      placeholder="请输入藏品编号(限50字)"
                      maxlength="50"
                      clearable>
            </el-input>
          </el-form-item>
          <el-form-item label-width="0">
            <el-button size="small" type="primary" @click="getDataList(1,1)" icon="el-icon-search">
              查询
            </el-button>
            <el-button size="small" @click="reseting(2)" icon='el-icon-refresh-right'>重置</el-button>
            <el-button size="small" @click="exportData(0)">
              <i class="icon-piliangdaochu iconfont buIcon"/>
              批量导出
            </el-button>
          </el-form-item>
        </el-form>
        <div style="margin-left: 25px">统计{{ search2.total }}件藏品</div>
        <el-table
            :data="generalList"
            size="small"
            style="margin-left: 20px"
            v-loading="search2.loading"
            height="calc(100vh - 330px)"
            class="table"
            :row-key="'id'"
            :header-cell-style="{background:'#F9DFDF'}"
            ref="multipleTable2"
            @selection-change="selectionChangeHandle"
        >
          <el-table-column :reserve-selection="true" type="selection" width="50"/>
          <el-table-column label="序号" type="index" width="50"></el-table-column>
          <el-table-column prop="name" label="图标" width="120px">
            <template slot-scope="scope">
              <div class="flex_a_c">
                <div v-if="scope.row.carded == 0" class="icon-wenwukapian iconfont listIcon"></div>
                <el-image
                    style="width: 36px; height: 36px"
                    :src="scope.row.picMasterViewUrl"
                    :fit="'cover'"
                    :preview-src-list="[scope.row.picMasterViewUrl]"
                >
                  <div slot="error" class="image-slot">
                    <el-image
                        style="width: 36px; height: 36px"
                        :src="require('@/assets/img/default.png')"
                        :fit="'cover'">
                    </el-image>
                  </div>
                </el-image>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="collectionName" label="藏品名称"
                           show-overflow-tooltip></el-table-column>
          <el-table-column prop="helpNum" label="辅助账编号" show-overflow-tooltip></el-table-column>
          <el-table-column prop="generalNum" label="总账编号" show-overflow-tooltip></el-table-column>
          <el-table-column prop="oneTypeName" label="藏品分类" show-overflow-tooltip></el-table-column>
          <el-table-column prop="identified" label="是否鉴定" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.identified == 0 ? '是' : '否' }}
            </template>
          </el-table-column>
          <el-table-column prop="leaveList" label="级别" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ getLeaveList(scope.row.collectionLevel) }}
            </template>
          </el-table-column>
          <el-table-column prop="integrityInfo" label="完残情况" show-overflow-tooltip></el-table-column>
          <el-table-column prop="integrity" label="完残程度" show-overflow-tooltip></el-table-column>
          <el-table-column prop="cStoreState" label="库存状态" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ $dictUtils.getDictLabel("cStore_state", scope.row.cStoreState, '-') }}
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="180">
            <template slot-scope="scope">
              <el-button style="margin-bottom: 10px" size="mini" type="text"
                         @click="appraisalResults(scope.row)">
                {{ scope.row.saveFlag == 0 ? '新增保护结果' : '修改保护结果' }}
              </el-button>
              <el-button style="margin-bottom: 10px" size="mini" type="text"
                         @click="detail(scope.row)">详情
              </el-button>
              <el-button size="mini" type="text"
                         @click="accidentImages(scope.row)">{{ `${scope.row.fileCount ? '查看' : '新增'}保护图片` }}
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="text_center">
          <el-pagination
              @size-change="sizeChangeHandle"
              @current-change="currentChangeHandle"
              :current-page="search2.current"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="search2.size"
              :total="search2.total"
              background
              layout="total, sizes, prev, pager, next, jumper"
          >
          </el-pagination>
        </div>
      </div>

      <div v-show="selectTab == '3'">
        <el-form size="small" ref="inputForm" inline :model="search3" label-width="100px">
          <el-form-item label="藏品名称" prop="name">
            <el-input v-model.trim="search3.name"
                      placeholder="请输入藏品名称(限50字)"
                      maxlength="50" clearable></el-input>
          </el-form-item>
          <el-form-item label="藏品编号" prop="code">
            <el-input v-model.trim="search3.code"
                      placeholder="请输入藏品编号(限50字)"
                      maxlength="50"
                      clearable>
            </el-input>
          </el-form-item>
          <el-form-item label-width="0">
            <el-button size="small" type="primary" @click="getDataList(1,1)" icon="el-icon-search">
              查询
            </el-button>
            <el-button size="small" @click="reseting(3)" icon='el-icon-refresh-right'>重置</el-button>
            <el-button size="small" @click="exportData(1)">
              <i class="icon-piliangdaochu iconfont buIcon"/>
              批量导出
            </el-button>
          </el-form-item>
        </el-form>
        <div style="margin-left: 25px">统计{{ search3.total }}件藏品</div>
        <el-table
            :data="auxiliaryList"
            size="small"
            style="margin-left: 20px"
            v-loading="search3.loading"
            height="calc(100vh - 330px)"
            class="table"
            :row-key="'id'"
            :header-cell-style="{background:'#F9DFDF'}"
            ref="multipleTable3"
            @selection-change="selectionChangeHandle"
        >
          <el-table-column :reserve-selection="true" type="selection" width="50"/>
          <el-table-column label="序号" type="index" width="50"></el-table-column>
          <el-table-column prop="name" label="图标" width="120px">
            <template slot-scope="scope">
              <div class="flex_a_c">
                <div v-if="scope.row.carded == 0" class="icon-wenwukapian iconfont listIcon"></div>
                <el-image
                    style="width: 36px; height: 36px"
                    :src="scope.row.picMasterViewUrl"
                    :fit="'cover'"
                    :preview-src-list="[scope.row.picMasterViewUrl]"
                >
                  <div slot="error" class="image-slot">
                    <el-image
                        style="width: 36px; height: 36px"
                        :src="require('@/assets/img/default.png')"
                        :fit="'cover'">
                    </el-image>
                  </div>
                </el-image>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="collectionName" label="藏品名称"
                           show-overflow-tooltip></el-table-column>
          <el-table-column prop="helpNum" label="辅助账编号" show-overflow-tooltip></el-table-column>
          <el-table-column prop="generalNum" label="总账编号" show-overflow-tooltip></el-table-column>
          <el-table-column prop="oneTypeName" label="藏品分类" show-overflow-tooltip></el-table-column>
          <el-table-column prop="identified" label="是否鉴定" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.identified == 0 ? '是' : '否' }}
            </template>
          </el-table-column>
          <el-table-column prop="leaveList" label="级别" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ getLeaveList(scope.row.collectionLevel) }}
            </template>
          </el-table-column>
          <el-table-column prop="integrityInfo" label="完残情况" show-overflow-tooltip></el-table-column>
          <el-table-column prop="integrity" label="完残程度" show-overflow-tooltip></el-table-column>
          <el-table-column prop="cStoreState" label="库存状态" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ $dictUtils.getDictLabel("cStore_state", scope.row.cStoreState, '-') }}
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="180">
            <template slot-scope="scope">
              <el-button style="margin-bottom: 10px" size="mini" type="text"
                         @click="appraisalResults(scope.row)">
                {{ scope.row.saveFlag == 0 ? '新增保护结果' : '修改保护结果' }}
              </el-button>
              <el-button style="margin-bottom: 10px" size="mini" type="text"
                         @click="detail(scope.row)">详情
              </el-button>
              <el-button size="mini" type="text"
                         @click="accidentImages(scope.row)">{{ `${scope.row.fileCount ? '查看' : '新增'}保护图片` }}
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="text_center">
          <el-pagination
              @size-change="sizeChangeHandle"
              @current-change="currentChangeHandle"
              :current-page="search3.current"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="search3.size"
              :total="search3.total"
              background
              layout="total, sizes, prev, pager, next, jumper"
          >
          </el-pagination>
        </div>
      </div>

      <div v-show="selectTab == '4'">
        <el-form size="small" ref="inputForm" inline :model="search4" label-width="100px">
          <el-form-item label="文物名称" prop="name">
            <el-input v-model.trim="search4.name"
                      placeholder="请输入文物名称(限50字)"
                      maxlength="50" clearable></el-input>
          </el-form-item>
          <el-form-item label-width="0">
            <el-button size="small" type="primary" @click="getDataList(1,1)" icon="el-icon-search">
              查询
            </el-button>
            <el-button size="small" @click="reseting(4)" icon='el-icon-refresh-right'>重置</el-button>
            <el-button size="small" @click="exportData(2)">
              <i class="icon-piliangdaochu iconfont buIcon"/>
              批量导出
            </el-button>
          </el-form-item>
        </el-form>
        <div style="margin-left: 25px">统计{{ search4.total }}件文物</div>
        <el-table
            :data="immovableList"
            size="small"
            style="margin-left: 20px"
            v-loading="search4.loading"
            height="calc(100vh - 330px)"
            class="table"
            :row-key="'id'"
            :header-cell-style="{background:'#F9DFDF'}"
            ref="multipleTable4"
            @selection-change="selectionChangeHandle"
        >
          <el-table-column :reserve-selection="true" type="selection" width="50"/>
          <el-table-column label="序号" type="index" width="50"></el-table-column>
          <el-table-column prop="picMasterViewUrl" label="图标" width="120px">
            <template slot-scope="scope">
              <div class="flex_a_c">
                <div v-if="scope.row.carded == 0"
                     class="icon-wenwukapian iconfont listIcon"></div>
                <el-image
                    style="width: 36px; height: 36px"
                    :src="scope.row.picMasterViewUrl"
                    :fit="'cover'"
                    :preview-src-list="[scope.row.picMasterViewUrl]"
                >
                  <div slot="error" class="image-slot">
                    <el-image
                        style="width: 36px; height: 36px"
                        :src="require('@/assets/img/default.png')"
                        :fit="'cover'">
                    </el-image>
                  </div>
                </el-image>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="文物名称"
                           show-overflow-tooltip></el-table-column>
          <el-table-column prop="culturalRelicsCategory" label="文物类别"
                           show-overflow-tooltip></el-table-column>
          <el-table-column prop="culturalRelicsType" label="文物分类" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.culturalRelicsType ? scope.row.culturalRelicsType : scope.row.other }}
            </template>
          </el-table-column>
          <el-table-column prop="quantity" label="数量" show-overflow-tooltip></el-table-column>
          <el-table-column prop="year" label="年份" show-overflow-tooltip></el-table-column>
          <el-table-column prop="heritageAppraisal" label="现状评估" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ $dictUtils.getDictLabel("assessment_status", scope.row.heritageAppraisal, '-') }}
            </template>
          </el-table-column>
          <el-table-column prop="integrityInfo" label="完残情况" show-overflow-tooltip></el-table-column>
          <el-table-column prop="integrity" label="完残程度" show-overflow-tooltip></el-table-column>
          <el-table-column fixed="right" label="操作" width="180">
            <template slot-scope="scope">
              <el-button style="margin-bottom: 10px" size="mini" type="text"
                         @click="appraisalResults(scope.row)">
                {{ scope.row.saveFlag == 0 ? '新增保护结果' : '修改保护结果' }}
              </el-button>
              <el-button style="margin-bottom: 10px" size="mini" type="text"
                         @click="detail(scope.row)">详情
              </el-button>
              <el-button size="mini" type="text"
                         @click="accidentImages(scope.row)">{{ `${scope.row.fileCount ? '查看' : '新增'}保护图片` }}
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="text_center">
          <el-pagination
              @size-change="sizeChangeHandle"
              @current-change="currentChangeHandle"
              :current-page="search4.current"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="search4.size"
              :total="search4.total"
              background
              layout="total, sizes, prev, pager, next, jumper"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <div class="text_center">
      <el-button size="small" @click="returnPage(1)">关闭</el-button>
    </div>

    <protection-results ref="protectionResults" @sureDa="getDataList()"></protection-results>
    <accident-images ref="accidentImages" @refreshList="getDataList()"></accident-images>
  </div>
</template>

<script>
import ProtectionResults from "@/views/modules/collection/business/protect/protectionResults.vue";
import AccidentImages from "@/views/modules/collection/business/fault/accidentImages.vue";

export default {
  name: "recordDetail",
  components: {AccidentImages, ProtectionResults},
  data() {
    return {
      id: '',
      selectTab: '999',
      tabsList: [
        {
          name: '基本信息',
          value: '999',
        },
        {
          name: '总账藏品',
          value: '2',
        },
        {
          name: '辅助账藏品',
          value: '3',
        },
        {
          name: '不可移动文物',
          value: '4',
        },
      ],

      inputForm: {
        protectEventName: '',
        protectionLevel: '',
        mttr: [],
        cycleStart: '',
        cycleEnd: '',
        protectionType: '',
        protectiveMaterial: '',
        protectiveEquipment: '',
        protectionCost: '',
        protectionPersonnel: '',
        specificProtectionMeasures: '',
        remark: '',
      },
      rules: {
        protectEventName: [
          {required: true, message: '请输入保护事件名称', trigger: 'blur'}
        ],
        protectionCost: [
          {required: true, message: '请输入保护费用', trigger: 'blur'}
        ],
        protectionPersonnel: [
          {required: true, message: '请输入保护人员', trigger: 'blur'}
        ],
        mttr: [
          {type: 'array', required: true, message: '请选择修复时间', trigger: 'blur'}
        ],
      },

      //总账藏品清单
      search2: {
        name: '',
        code: '',
        current: 1,
        size: 10,
        total: 0,
        loading: false,
      },
      generalList: [],
      dataListSelect2: [],

      //辅助账藏品清单
      search3: {
        name: '',
        code: '',
        current: 1,
        size: 10,
        total: 0,
        loading: false,
      },
      auxiliaryList: [],
      dataListSelect3: [],

      //不可移动文物
      search4: {
        name: '',
        current: 1,
        size: 10,
        total: 0,
        loading: false,
      },
      immovableList: [],
      dataListSelect4: [],

      inputType: true,
      returnPageStatus: '',
      butType: "", //0新增 1详情 2修改 4审核
      leaveList: [],
    }
  },

  mounted() {
    this.id = this.$route.query.id
    if (this.id) {
      this.getRowDetail()
    }
    let listSearch = JSON.parse(sessionStorage.getItem('listSearch2'))
    if (listSearch) {
      this.selectTab = listSearch.selectTab
      this.search1 = listSearch.search1
      this.search2 = listSearch.search2
      this.search3 = listSearch.search3
      this.search4 = listSearch.search4
      this.handleClick()
      sessionStorage.removeItem('listSearch2')
    }
    this.$axios(this.api.collection.listLevelSelect).then(data => {
      if (data.status) {
        this.leaveList = data.data
      }
    })
  },

  methods: {
    //获取详情
    getRowDetail() {
      this.$axios(this.api.collection.collectionProtectionGetById + this.id, {}, 'get').then(data => {
        if (data.status) {
          this.inputForm = data.data
          if (this.inputForm.cycleStart) {
            this.$set(this.inputForm, 'mttr', [this.inputForm.cycleStart, this.inputForm.cycleEnd])
          }
        }
      })
    },

    //查询列表
    getDataList(num, type) {
      if (num == 1) {
        this['search' + this.selectTab].current = 1
      }
      if (type) {
        this.$refs['multipleTable' + this.selectTab].clearSelection()
      }
      let data = JSON.parse(JSON.stringify(this['search' + this.selectTab]))
      this.$set(data, 'id', this.id)
      if (this.selectTab == 2 || this.selectTab == 3) {
        this.$set(data, 'type', this.selectTab - 2)
      } else {
        this.$set(data, 'type', 3)
      }
      this['search' + this.selectTab].loading = true;
      let feachUrl = this.api.collection.collectionProtectionCollectionPage
      if (this.selectTab == 4) {
        feachUrl = this.api.collection.collectionProtectionCollectionPage2
      }
      this.$axios(feachUrl, data, 'get').then(data => {
        if (data.status) {
          if (this.selectTab == 2) {
            this.generalList = data.data.records
          }
          if (this.selectTab == 3) {
            this.auxiliaryList = data.data.records
          }
          if (this.selectTab == 4) {
            this.immovableList = data.data.records
          }
          this['search' + this.selectTab].total = parseInt(data.data.total)
          this['search' + this.selectTab].loading = false;
        }
      })
    },

    //修复结果
    appraisalResults(row) {
      this.$refs.protectionResults.init(row, this.id)
    },

    //表格勾选数据
    selectionChangeHandle(val) {
      this['dataListSelect' + this.selectTab] = val
    },

    //详情
    detail(row) {
      let listSearch = {
        selectTab: this.selectTab,
        search1: this.search1,
        search2: this.search2,
        search3: this.search3,
        search4: this.search4,
      }
      sessionStorage.setItem('listSearch2', JSON.stringify(listSearch))
      if (this.selectTab == 1) {
        this.$router.push({
          path: '/collection/accounts/addCollection',
          query: {
            butType: 8,
            id: row.id,
            archivesBasicDataId: row.archivesBasicDataId,
            typePage: 'museum',
            see: 1
          }
        })
      }
      if (this.selectTab == 2) {
        this.$router.push({
          path: '/collection/accounts/addCollection',
          query: {
            butType: 3,
            id: row.id,
            archivesBasicDataId: row.archivesBasicDataId,
            typePage: 'ledger',
            see: 1
          }
        })
      }
      if (this.selectTab == 3) {
        this.$router.push({
          path: '/collection/accounts/addCollection',
          query: {
            butType: 3,
            id: row.id,
            archivesBasicDataId: row.archivesBasicDataId,
            typePage: 'auxiliaryAccount',
            see: 1
          }
        })
      }
      if (this.selectTab == 4) {
        this.$router.push({
          path: '/collection/immovable/addMovable',
          query: {butType: 1, id: row.id, typePage: 'manage', see: true}
        })
      }
    },

    //上传/查看事故图片
    accidentImages(row) {
      this.$refs.accidentImages.init(row,this.id,'bhqk',3)
    },

    reseting(num) {
      if (num == 2) {
        this.search2.name = ''
        this.search2.code = ''
      }
      if (num == 3) {
        this.search3.name = ''
        this.search3.code = ''
      }
      if (num == 4) {
        this.search4.name = ''
      }
      this.getDataList(1, 1)
    },

    handleClick(tab, event) {
      this.getDataList()
    },

    //返回
    returnPage(num) {
      this.returnPageStatus = num
      this.$store.dispatch('tagsView/delView', this.$route).then(({visitedViews}) => {})
      this.$router.back()
    },

    // 展览每页数
    sizeChangeHandle(val) {
      this['search' + this.selectTab].size = val
      this.getDataList(1, 1)
    },
    // 展览当前页
    currentChangeHandle(val) {
      this['search' + this.selectTab].current = val
      this.getDataList();
    },

    //导出
    exportData(type) {
      if (this['dataListSelect' + this.selectTab].length == 0) {
        this.$message.warning('请至少选择一条数据')
        return
      }
      let ids = this['dataListSelect' + this.selectTab].map(item => {
        if (item) {
          return item.id
        }
      })
      let data = {
        id: this.id,
        ids: ids,
        type: type,
      }
      this.exportExcel(this.api.collection.collectionProtectionCollectionExport, data, '保护情况记录藏品', 'post')
      this.getDataList('', 1)
    },

    jiage(value) {
      // 使用正则表达式来限制输入为正数，并保留两位小数
      value = value.replace(/[^\d.]/g, ""); //清除"数字"和"."以外的字符
      value = value.replace(/^\./g, ""); //验证第一个字符是数字
      value = value.replace(/\.{2,}/g, "."); //只保留第一个, 清除多余的
      value = value.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
      value = value.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); //只能输入两位个小数
      this.inputForm.repairCost = value
    },

    save2Data() {
      if (!this.inputForm.repairCost) {
        return
      }
      let menoy = this.inputForm.repairCost.split('.')
      if (menoy.length != 2) {
        this.inputForm.repairCost = this.inputForm.repairCost + '.00'
      } else {
        if (menoy[1].length == 0) {
          this.inputForm.repairCost = this.inputForm.repairCost + '00'
        }
        if (menoy[1].length == 1) {
          this.inputForm.repairCost = this.inputForm.repairCost + '0'
        }
      }
    },

    getLeaveList(leave) {
      let data = this.leaveList.filter(item => {
        return item.level == leave
      })
      if (data.length) {
        return data[0].levelName
      }
    },

  },

  //离开此页面
  beforeRouteLeave(to, from, next) {
    // 在这里编写逻辑来决定是否允许离开
    // 如果允许离开，调用 next() 方法
    // 如果不允许离开，调用 next(false) 方法
    if (!this.returnPageStatus) {
      sessionStorage.removeItem('listSearch')
    }
    next();
  },
}
</script>

<style scoped>
.bg-white {
  overflow-y: auto !important;
}

/*去掉tabs底部的下划线*/
.tabsCls >>> .el-tabs__nav-wrap::after {
  position: static !important;
}

.contens {
  height: calc(100vh - 260px);
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 10px;
}

.adsfaads {
  margin-bottom: 15px !important;
}

.minTitle {
  font-weight: bold;
  font-size: 14px;
  margin-top: 20px;
}
</style>
